import React from "react";
// import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
// import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import AgribusinessCustomersBenefits from "./AgribusinessCustomersBenefits";
import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";


export default function AgribusinessCustomers(props) {
  const exploreButton = props.exploreBtn;
  return (
    <>
      <HeaderComponent />{" "}
      <UpdatesBanner
        className="AAMCBanner"
        bannerText="Agribusiness Customers"
      />
      <Container>
        <AgribusinessCustomersBenefits />
      </Container>
      <Container>
        <h4 className="container-title text-center mx-md-4 ">Customers</h4>
      </Container>
      <div className="subSectionWrapper main-wrapper2">
      

        <HomeSubSection
          id="FEN-Tenders"
          className3="image4"
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards"
          descriptionTitle="
        B2B Buyers"
          description="B2B Buyers are customers of agribusiness, purchasing raw products for use in the food and beverage industry. This relationship helps agribusiness expand their market presence and generate consistent revenue."
          // description="Through FEN  distributor marketplace portal allows the corporate buyers to act as local distributor and exporters . Through this portal, corporate buyers keep track of transactions, make online orders and track the orders . The benefits for corporate buyers is to get quantity, quality and availability market fit assets for processing that have a direct impact on operations of downstream food supplier companies."
          // description1="Forecasting | Quality Certified| Reliable Supply | Fair Price | Improved Operational Efficency"
          src={ExporterImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          // href1="auth/21/signin"
          // buttoncont1={`${exploreButton ? "Explore Exporters" : "Sign In"} `}
          // exploreButton={exploreButton}
        //   href3="/b2b-buyers"
        //   buttoncont3="Explore More"
          onClick={() => {
            localStorage.setItem("userType", "Buyer");
          }}
          description1="Corporate Buyers | Merchant Exporters | Distributors | Wholesalers | Brands | Retailers "
        />
        <HomeSubSection
          id="FEN-Investor"
          className3="agri-foodmanufacturer-img"
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards"
          descriptionTitle="
        Food Processing Enterprises"
          description="Food Processing Enterprises are customers of agribusiness, acquiring raw agricultural products for processing into finished goods. This partnership helps agribusiness secure consistent demand and supports the growth of the food production value chain.
        "
          description1="Food processor "
        //   href3="/food-processing-enterprises"
        //   buttoncont3="For Food Processing Enterprises        "
          href2="/auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "FOOD-PROCESSOR");
          }}
        />


<HomeSubSection
          id="ABN-Govt"
          className3="forManufactures"
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards"
          descriptionTitle="
         Brands"
          description="Brands are key customers of agribusiness, leveraging their products for various consumer markets. These enterprises provide essential processing services, enabling brands to deliver high-quality goods to their target audiences efficiently. "
          // description1="Local Goverment Institutions | Central Goverment Institutions | Private Institutions "
          src={GovernmentOrganizationImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          // href1="auth/8/signin"
          // buttoncont1={`${
          //   exploreButton ? "Explore Government Organizations" : "Sign In"
          // } `}
          exploreButton={exploreButton}
        //   href3="/ForBrands"
        //   buttoncont3="Explore More"
          onClick={() => {
            localStorage.setItem("userType", "BRAND");
          }}
        />

        <HomeSubSection
          id="ABN-Tenders"
          className3="image4"
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards"
          descriptionTitle="
        F&B Buyers"
          description="F&B buyers are customers of agribusiness, sourcing products for their restaurants, stores, or distribution networks. These buyers rely on the enterprises to deliver high-quality, consistent products that meet their business needs."
          // description="Through CMN distributor marketplace portal allows the corporate buyers to act as local distributor and exporters . Through this portal, corporate buyers keep track of transactions, make online orders and track the orders . The benefits for corporate buyers is to get quantity, quality and availability market fit assets for processing that have a direct impact on operations of downstream food supplier companies."
          // description1="Forecasting | Quality Certified| Reliable Supply | Fair Price | Improved Operational Efficency"
          src={ExporterImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          // href1="auth/21/signin"
          // buttoncont1={`${exploreButton ? "Explore Exporters" : "Sign In"} `}
          // exploreButton={exploreButton}
        //   href3="/f&b-buyers"
        //   buttoncont3="Explore More"
          onClick={() => {
            localStorage.setItem("userType", "Buyer");
          }}
          description1="Corporate Buyers | Merchant Exporters | Distributors | Wholesalers | Brands | Retailers "
        />
      </div>
      <FooterComponent />
    </>
  );
}
