import React from 'react'
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar'

export default function CFNavbar() {
    const navData = [
    {to:"/social-cf-marketplace", link:"For Farm Enterprise"},
    {to:"/social-cf-marketplace-customers", link:"For Food Processing Enterprise"},
    {to:"/social-cf-marketplace-customers", link:"For Brands"},

  ]
//   const navData2 = [{to:"/AgriBusiness", link:"Agri-Business"},
//   {to:"/Brands", link:"Brands"},
// ]
  return (
    <><SecondaryNavbar props={navData}  /></>
  )
}
