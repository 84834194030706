import React from "react";
import AgribusinessImg from "../../images/abn-beneficaries/agribusiness.jpg";
import VendorsImg from "../../images/abn-beneficaries/vendors.jpg"
import CustomerImg from "../../images/abn-beneficaries/customers.jpg"
import CapitalProvidersImg from "../../images/abn-beneficaries/capital-providers.jpg"

import { Row, Col } from "react-bootstrap";
import { Container } from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default function ABNBeneficiaries() {
  const services = [
    { title: "Core Agribusiness", image: AgribusinessImg, href:"/agribusiness" },
    { title: "Core Agribusiness Vendors", image: VendorsImg, href:"/agribusiness-vendors" },
    { title: "Core Agribusiness Customers ", image: CustomerImg, href:"/agribusiness-customers" },
    { title: "Core Agribusiness Capital Providers", image: CapitalProvidersImg, href:"/agribusiness-capital-providers" },
  ];

  return (
    <Container className="for-farmer-section2 subSectionWrapper">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
        ABN Beneficiaries
      </h2>
      <Row className="justify-content-center">
        {services.map((e, index) => {
          return (
            <Col key={index} md={3} className="position-relative">
                          <NavLink to = {e.href}>

              <div className="data-container position-relative mb-4">
                <img src={e.image} alt={e.title} />
                <h3>{e.title}</h3>
              </div>
              </NavLink>

            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
