import React from "react";
import onMarketplace from "../../images/marketplace-icons/on-marketplace.svg";
import BulkonMarketplace from "../../images/marketplace-icons/on-marketplace.svg";
import GridBanner from "../GridBanner/GridBanner";

export default function AgriOutputMarketplace() {
  const apps = [
    {
      href: "/onMarketplace",
      image: onMarketplace,
      title: "onMarketplace",
    },

    {
      href: "/bulk-onmarketplace",
      image: BulkonMarketplace,
      title: "BulkonMarketplace",
    },
   
    
  ];

  return (
   <GridBanner data={apps}/>
  );
}
