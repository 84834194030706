import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import prod2 from "../../../images/substance.png";
import { Container } from "react-bootstrap";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import ExoploreProductBanner from "../../ExploreProductBanner/ExoploreProductBanner";
import SecondaryNavbar from "../../SecondaryNavbar/SecondaryNavbar";
import { NavHashLink } from "react-router-hash-link";
import ProcessedProducts from "../ProcessedProducts";


const ProcessingServiceMarketplace = () => {
  const [activeCategory, setActiveCategory] = useState("Insurance");
  const [userType] = useState(["FOOD-PROCESSOR"]);

  const titles = {
    "ContractManufacturing": "Contract Manufacturing",
    "ProcessingAdvisory": "Processing Advisory",
    "MachineryAdvisory": "Machinery Advisory",
  

  };

  const id = {
    "Insurance": "Insurance",
    "AgriAdvisory": "AgriAdvisory",
    "SoilTest": "SoilTest",
    "WaterTest": "WaterTest",
    "QualityAssaying": "QualityAssaying",
    "WareHousing": "WareHousing",
    "Spraying/DroneService": "Spraying/DroneService",
    "FinanceService":"FinanceService"

  };

  const bannerCategory = [
    {
      title: titles["ContractManufacturing"],
      image: prod2,
      category: "ContractManufacturing",
      id: `#${id["ContractManufacturing"]}`,
    },
    {
      title: titles["ProcessingAdvisory"],
      image: prod2,
      category: "ProcessingAdvisory",
      id: "#ProcessingAdvisory",
    },
    {
      title: titles["MachineryAdvisory"],
      image: prod2,
      category: "MachineryAdvisory",
      id: "#MachineryAdvisory",
    },


  ];

  const handleCategoryChange = (category) => {
    console.log("Selected Category:", category); // Debug to ensure category change
    setActiveCategory(category);
  };

  return (
    <>
    <HeaderComponent/>
      <div className="serviceContainer ExploreBannerContainer">
        <div className="gridSection">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            direction="row"
          >
            {bannerCategory.map(({ title, image, category, id }) => (
              <Grid
                key={category}
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={3}
                sm={4}
                xs={6}
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleCategoryChange(category);
                  }}
                  href={id}
                >
                  <img src={image} alt={title} />
                  <h4>{title}</h4>
                </a>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>

      <div className="explore-product-wrapper">
        <Container>
          <div id="tendersContainer" className="container-fluid">
            <h3 id={id[activeCategory]} className="container-title">
              {titles[activeCategory]}
            </h3>
            {/* Use key to force re-render on category change */}
            <ProcessedProducts
              key={activeCategory} 
              type={activeCategory}  // Ensure type updates correctly
              userType={userType}
            />
          </div>
        </Container>
      </div>
      <FooterComponent/>
    </>
  );
};

export default ProcessingServiceMarketplace;
