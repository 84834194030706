import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../../images/Agri-Business-removebg-preview.png";
import production from "../../../images/LandingPageChallenge/production (2).svg";
import Ecosystem from "../../../images/LandingPageChallenge/blockchain.svg";
import Export from "../../../images/LandingPageChallenge/export.svg";
import Sourcing from "../../../images/LandingPageChallenge/global-search.svg";
import Finance from "../../../images/LandingPageChallenge/income.svg";
import Technology from "../../../images/LandingPageChallenge/nanotechnology.png";

import LaunchBusiness from "../../../images/LandingPageChallenge/e-commerce.svg";
import OwnCommunity from "../../../images/LandingPageChallenge/team.svg";
import Stustainability from "../../../images/LandingPageChallenge/sustainability.svg";
import TransactSecurely from "../../../images/LandingPageChallenge/secure-payment.svg";
import ManageOperations from "../../../images/LandingPageChallenge/operational-excellence.svg";
import ReduceCost from "../../../images/LandingPageChallenge/price-down.svg";
import Provenance from "../../../images/LandingPageChallenge/research.svg";
import ImproveHealth from "../../../images/LandingPageChallenge/health-care.svg";



const ChallengesData = [{title:""}]

export default function LandingPageChallenge() {
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
        Why AI-Driven ABN
      </h2>

      <Row className="align-items-center">
        {/* <Col md={7} lg={4} className="mx-md-auto">
          <img src={challangeImg} className="mb-md-0 mb-4" />
        </Col> */}

        <Row className="challangesRow">
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-4 mb-3">
              <img src={Sourcing} className="mb-3" />
              <h6 className="mb-2">Sourcing Efficiently</h6>
              <p>
                One of the challenges faced in sourcing is the difficulty of
                obtaining good-quality raw materials at a price that is
                affordable.{" "}
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-4 mb-3">
              <img src={production} className="mb-3" />
              <h6 className="mb-2">Increase Production </h6>
              <p>
                Agribusiness encounters production challenges due to the
                scarcity of quality raw materials, hindering efforts to enhance
                yields.{" "}
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-4 mb-3">
              <img src={Export} className="mb-3" />
              <h6 className="mb-2">Export Easily</h6>
              <p>
                Limited access to export network & connection, Lack of
                understand of international market including consumer
                preference, market trends & distribution channels.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={Ecosystem} className="mb-3" />
              <h6 className="mb-2">Easily Advisory Access</h6>
              <p>
                There is lack of ecosystem for agribusiness from where they get
                support in terms of advisory, training , certification,
                documentation , government schemes etc.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={Technology} className="mb-3" />
              <h6 className="mb-2">Easily Technology Access</h6>
              <p>
                Lack of technology ecosystem, adoption and access to right
                industry 4.0 enabled machinery to improve productivity,
                efficiency, and ability to compete in the market .{" "}
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={Finance} className="mb-3" />
              <h6 className="mb-2">Easily Finance Access</h6>
              <p>
                Agribusiness lacks diverse financing options, often resorting to
                banks and government grants. There's a demand for affordable and
                easy alternatives to sustain successful operations.{" "}
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={LaunchBusiness} className="mb-3" />
              <h6 className="mb-2">Launch eCommerce Business Quickly</h6>
              <p>
                Agribusinesses face challenges like managing perishable goods,
                coordinating supply chains, and setting up logistics, while also
                addressing regulatory requirements and building a strong digital
                platform for online success.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={OwnCommunity} className="mb-3" />
              <h6 className="mb-2">Build & Operate Own Community</h6>
              <p>
                Agribusinesses face difficulties in creating and maintaining
                their own communities, including engaging diverse stakeholders,
                fostering collaboration, and ensuring consistent communication
                across farmers, suppliers, and customers while managing
                operational complexities.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={Stustainability} className="mb-3" />
              <h6 className="mb-2">Achieve ESG / Sustainability Goals</h6>
              <p>
                Agribusinesses struggle to meet Environmental, Social, and
                Governance (ESG) goals due to the complexities of reducing
                resource usage, improving supply chain transparency, and
                ensuring sustainable farming practices, all while balancing
                profitability and regulatory demands.
              </p>
            </div>
          </Col>{" "}
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={TransactSecurely} className="mb-3" />
              <h6 className="mb-2">Transact Securely</h6>
              <p>
                Agribusinesses face challenges in ensuring secure transactions,
                including safeguarding sensitive financial data, preventing
                fraud, and maintaining trust in digital payment systems,
                especially when dealing with complex supply chains and global
                partners.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={ManageOperations} className="mb-3" />
              <h6 className="mb-2">Manage Operations Efficiently</h6>
              <p>
                Agribusinesses struggle with streamlining operations, which
                includes optimizing resource use, coordinating supply chains,
                and improving productivity, all while managing the complexities
                of farming cycles, processing, and logistics.
              </p>
            </div>
          </Col>{" "}
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={ReduceCost} className="mb-3" />
              <h6 className="mb-2">Reduce Cost</h6>
              <p>
                Agribusinesses face challenges in lowering costs, including
                optimizing resource usage, improving supply chain efficiency,
                and minimizing waste, all while maintaining product quality and
                meeting regulatory standards.
              </p>
            </div>
          </Col>
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={Provenance} className="mb-3" />
              <h6 className="mb-2">Facilitate Traceability & Provenance</h6>
              <p>
                Agribusinesses face challenges in ensuring full traceability,
                from farm to consumer, including tracking product origins,
                maintaining transparent records, and meeting regulatory
                standards, while managing complex supply chains.
              </p>
            </div>
          </Col>{" "}
          <Col md={6} lg={4} sm={6} xs={10} className="d-flex">
            <div className="challangeCards mb-md-0 mb-3">
              <img src={ImproveHealth} className="mb-3" />
              <h6 className="mb-2">Improve Crop and Livestock health</h6>
              <p>
                Agribusinesses face difficulties in enhancing crop and livestock
                health, including adopting advanced farming techniques, managing
                diseases, and optimizing nutrition, all while ensuring
                sustainability and cost-effectiveness.
              </p>
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}
