import React from 'react';
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ClubMainPage from '../Club/ClubMainPage';
// import "./ContractManufacturing.css"
import SocialMarketNavbar from '../SocialMarketNavbar/SocialMarketNavbar';
import SocialOnMarketplaceService from '../AgriBusiness/SocialOnMarketplaceService';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import CMNavbar from './CMNavbar';

export default function ContractManufacturingService() {

  return (
    <>
    <HeaderComponent/>
    <CMNavbar/>

    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText={["Start exploring approved food processing enterprise who are providing  ",<br className='d-md-block d-none'></br>, "Contract Manufacturing Services "]}
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 

<div className="subSectionWrapper">
      <h2 className='container-title mb-md-5 text-center'>Food-Processing Enterprises</h2>

        <ClubMainPage type={["FOOD-PROCESSOR"]} bodycopy="Processing Enterprise Not Found" registerUser= "BRAND" rfq = "true"/>
      </div>
      {/* <div className="subSectionWrapper">
      <h2 className='container-title mb-md-5 text-center'>Farm Enterprises</h2>

        <ClubMainPage type={["AssetDeveloper"]}  rfq = "true"/>
      </div> */}
            {/* <SocialOnMarketplaceService /> */}

<FooterComponent/>
    </>
  )
}
