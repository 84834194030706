import React from "react";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
// import "./OnMarketplaceSell.css";
import MarketPlaceQuote from "../MarketPlaceQuote/MarketPlaceQuote";
import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";

import { useLocation, useHistory } from "react-router-dom";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";

export default function D2ConMarketplaceSeller(){
  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;
  let navData = [
    { to: "/d2c-onmarketplace", link: "For Consumers",  },
     { to: "/d2c-onmarketplace-food-processing-enterprise", link: "For Food Processing Enterprise" }
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];


    return(
        <>
        <HeaderComponent/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling processed organic products with ABN"
         buttonText="Sign Up"
         href="auth/3/signup"
         clicked={()=>{
          localStorage.setItem("userType", "FOOD-PROCESSOR")
         }}
        />
        <FooterComponent/>
        {/* <MarketPlaceQuotePP requestType = "agri-buy-products-rfq-req" category= "PROCESSED-PRODUCTS" heading="Explore RFQ" /> */}
        </>
    )
}