import React from "react";
import onMarketplace from "../../images/marketplace-icons/on-marketplace.svg";
import BulkonMarketplace from "../../images/marketplace-icons/on-marketplace.svg";
import GridBanner from "../GridBanner/GridBanner";

export default function ProcessedProductMarketplace() {
  const apps = [
    {
      href: "/b2b-onmarketplace",
      image: onMarketplace,
      title: "B2BonMarketplace",
    },

    {
      href: "/d2c-onmarketplace",
      image: BulkonMarketplace,
      title: "D2ConMarketplace",
    },
   
    
  ];

  return (
    
   <GridBanner data={apps}/>
  );
}
