import React from "react";
import AgriOutputMarketplace from "../../images/marketplaces/agrioutput-marketplace.jpg";
import SocialOnMarketplace from "../../images/marketplaces/social-onmarketplace.jpg";
import SocialIaaMarketplace from "../../images/marketplaces/social-iaa-marketplace.jpg";
import AgroCareMarketplace from "../../images/marketplaces/agrocare-marketplace.jpg";
import AssetMarketplace from "../../images/marketplaces/asset-marketplace.jpg";
import ProcessedProductMarketplace from "../../images/marketplaces/processed-product-marketplace.jpg";

import "./Marketplaces.css";
import { Row, Col } from "react-bootstrap";
import { Container } from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default function MarketPlaces() {
  const services = [
    { title: "AgriOutputMarketplace", image: AgriOutputMarketplace, href:"/agri-output-marketplace" },
    { title: "Social onMarketplace", image: SocialOnMarketplace, href:"/social-on-marketplace" },
    { title: "Social iaaMarketplace", image: SocialIaaMarketplace, href:"/iaaMarketplace" },
    { title: "Agrocare Marketplace", image: AgroCareMarketplace, href:"/agrocare-marketplace" },
    { title: "AssetMarketplace", image: AssetMarketplace, href:"/asset-marketplace" },
    { title: "ProcessedProductMarketplace", image: ProcessedProductMarketplace, href:"/processed-product-marketplace" },
    { title: "AgriServiceMarketplace", image: ProcessedProductMarketplace, href:"/agri-service-marketplace" },
    { title: "ProcessingServiceMarketplace", image: ProcessedProductMarketplace, href:"/processing-service-marketplace" },

  
  ];

  return (
    <Container className="for-farmer-section2 subSectionWrapper">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
        ABN Marketplaces
      </h2>
      <Row className="justify-content-center">
        {services.map((e, index) => {
          return (
            <Col key={index} md={3} className="position-relative">
                          <NavLink to = {e.href}>

              <div className="data-container position-relative mb-4">
                <img src={e.image} alt={e.title} />
                <h3>{e.title}</h3>
              </div>
              </NavLink>

            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
