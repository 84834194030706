import React from "react";
import Icon1 from "../../images/CustomerBenefit/return-on-investment.svg"
import Icon2 from "../../images/CustomerBenefit/quality-assurance.svg"
import Icon3 from "../../images/CustomerBenefit/time-manager.svg"
import Icon4 from "../../images/CustomerBenefit/no-preservatives.svg"
import Icon5 from "../../images/CustomerBenefit/traceability.svg"

import { Row, Col } from "react-bootstrap";
import { Container } from "@mui/material";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function AgriBusinessVendorsBenefits() {
  const benefits = [
   
    {
      image: Icon1,
      title: "Increase in Sales",
      bodycopy:
        "Increased sales enable agribusiness vendors to boost revenue, enhancing their financial stability. This growth also supports long-term business sustainability and market expansion.",
    },
    {
      image: Icon1,
      title: "Reduce Marketing Cost",
      bodycopy:
        "Agribusiness vendor  can reach a larger customer base without having to manage multiple accounts on different online e-commerce platforms. This reduces the need for separate marketing efforts on each platform.",
    },
  

  

    
  
  ];
  return (
    <>
    <div className="for-farmer-section3 subSectionWrapper">
      <h2 className="title text-center mb-5 mb-lg-5 container-title"> Benefits</h2>
      <Row className="justify-content-center">
        {benefits.map((e) => {
          return (
            <Col md={4}>
              <BenefitsCard src={e.image} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
    </>

  );
}
