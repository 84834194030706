import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import { Container } from "@material-ui/core";
import ProcessedProducts from "./ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";

const D2ConMarketplace = () => {
  const userType = ["FOOD-PROCESSOR"];
  let navData = [
    { to: "/d2c-onmarketplace", link: "For Consumers" },
    {
      to: "/d2c-onmarketplace-food-processing-enterprise",
      link: "For Food Processing Enterprise",
    },
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

  return (
    <>
    <HeaderComponent/>
      <SecondaryNavbar props={navData} />

      <UpdatesBanner
        className="ExploreBannerContainer text-white"
        bannerText="Ensured Quality | Traceability  | Provenance"
        bannerbodycopy="Ensure food saftey across globe

        "
      />

      <div className="explore-product-wrapper">
        <Container>
          <div id="tendersContainer">
            <h3 id="Processed-Products" className="container-title  ">
              Processed Products
            </h3>
            <ProcessedProducts
              quote="true"
              type="PROCESSED-PRODUCTS"
              userType={userType}
            />
          </div>
        </Container>
      </div>
      <FooterComponent/>
    </>
  );
};

export default D2ConMarketplace;
