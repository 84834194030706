import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Icon1 from "../../images/app2.png";
import Icon2 from "../../images/app6.png";
import Icon3 from "../../images/app4.png";
import Icon4 from "../../images/market-positioning (1).png";
import Icon5 from "../../images/socialmarketW.svg";
import Icon6 from "../../images/retail (1).svg";
import Icon7 from "../../images/farming.svg";
import Icon8 from "../../images/talking.svg";


import { NavHashLink } from "react-router-hash-link";

export default function ExploreTechnologyBanner() {
  const apps = [
    // {
    //   href: "#DTNApp",
    //   image: Icon2,
    //   title: "DTN App",
    // },
    {
      href: "#ABNNetwork",
      image: Icon1,
      title: "ABN Network",
    },
    // {
    //   href: "#CBNApp",
    //   image: Icon8,
    //   title: "CBN App",
    // },
    {
      href: "#MinerApp",
      image: Icon3,
      title: "Miner App",
    },
    // {
    //   href: "#onMarketplace",
    //   image: Icon4,
    //   title: "onMarketplace",
    // },

    // {
    //   href: "#SocialonMarketplace",
    //   image: Icon5,
    //   title: "Social onMarketplace",
    // },
    // {
    //   href: "#SocialiaaMarketplace",
    //   image: Icon6,
    //   title: "Social iaaMarketplace",
    // },
    // {
    //   href: "#AgrocareMarketplace",
    //   image: Icon7,
    //   title: "Agrocare Marketplace",
    // },
  ];

  return (
    <div maxwidth={12} className="businessPartnerContainer agristackContainer">
      <Container maxWidth="sm" className="gridSection ">
        <Grid container={true} alignItems="center" spacing={1} direction="row">
          {apps.map((e) => {
            return (
              <Grid
                id="ecoSystemView_link"
                className="hoverEffect mb-3"
                item
                md={6}
                sm={4}
                xs={6}
              >
                <NavHashLink to={e.href}>
                  <img src={e.image} alt="ecoSystem & Mobile Apps View " />
                  <h4>{e.title}</h4>
                </NavHashLink>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
