import React from "react";
// import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
// import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AgriBusinessVendorsBenefits from "./AgriBusinessVendorsBenefits";

export default function AgriBusinessVendors(props) {
  const exploreButton = props.exploreBtn;
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="AAMCBanner" bannerText="AgriBusiness Vendors"/>
    <Container>
    <AgriBusinessVendorsBenefits/>
    </Container>
    <div className="subSectionWrapper main-wrapper2">
      <Container>
        <h4 className="container-title text-center mx-md-4 ">
          
          Vendors
        </h4>

     
      </Container>
   

 


    
      <HomeSubSection
        id="FEN-Advertisement"
        className3="image3"
        className="rowReverseAbout borderedCards"
        description="Agri Input Providers are key vendors of agribusiness, supplying essential agricultural inputs like seeds, fertilizers, and equipment. Their support ensures that agribusiness have the necessary resources to optimize production and achieve higher yields.
                "
        descriptionTitle="
                Agri Input Providers & Agrodealers" 
        description1="FMC | PMC | AHSP | AIC | Feed Processing Machinery Company"
        src={MachineryImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/20/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Machinery Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        // href3="/agri-input-providers"
        // buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />

    




 
      <HomeSubSection
        id="FEN-Transporters"
        className3="image5"
        className="rowReverseAbout--mod borderedCards"
        description="Logistic Providers are vendors of agribusiness, responsible for transporting agricultural products and inputs. They ensure efficient and timely delivery, supporting smooth operations across the supply chain.
        "
        // description="FEN  Platform provides logistics faeture. With FEN  Platform, logistics companies and their staff can manage their entire fleet, booking details, trips scheduling, scheduling loads & orders to their own drivers or 3rd party drivers."
        descriptionTitle=" Logistic Providers"
        src={MSMEImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/13/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore  Logistic Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        // href3="/logistic-providers"
        // buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "LogisticProvider");
        }}
        description1= "Global Logistic Providers | Hyperlocal Transporters | Cold Chain Logistics Providers "
      />


    
<HomeSubSection
          id="ABN-Advertisement"
          className3="image3"
          className="rowReverseAbout borderedCards"
          description="Processing machinery providers are partners of agribusiness, supplying essential equipment and technology for efficient and effective production. These partnerships ensure that food processing operations are equipped with the latest machinery to maintain high standards of quality and productivity.
                "
          descriptionTitle="
        Processing Machinery Providers"
          description1="Food Processing Machinery-PMC | Packaging Material Companies (Packaging , labeling , printing ) "
          src={MachineryImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          // href1="auth/20/signin"
          // buttoncont1={`${
          //   exploreButton ? "Explore Machinery Providers" : "Sign In"
          // } `}
          exploreButton={exploreButton}
        //   href3="/processing-machinery-providers"
        //   buttoncont3="Explore More"
          onClick={() => {
            localStorage.setItem("userType", "PMC");
          }}
        />

<HomeSubSection
          id="ABN-Investor"
          className3="agribusiness-img"
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards"
          descriptionTitle="
                Farm Enterprise"
          description="Farm enterprises are partners of agribusinesses, supplying raw agricultural products that are essential for production. This collaboration ensures a steady supply of quality inputs for processing and helps streamline the farm-to-fork supply chain.

        "
          description1="Organic farming companies | Agri-asset management companies"
        //   href3="/ForAAMC"
        //   buttoncont3="For Farm Enterprise"
          href2="/auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "AssetDeveloper");
          }}
        />
        <HomeSubSection
          id="ABN-Investor"
          className3="cbo-img"
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards"
          descriptionTitle="
        Community Based Organizations"
          description="Community-based organizations (CBOs) are partners of agribusiness, supporting local engagement and providing resources or services that enhance community involvement. They play a crucial role in facilitating supply chains, promoting sustainable practices, and connecting enterprises with local needs and resources.
        "
          description1="FPCs | NGOs | PACS | Traders | SHG | CS"
        //   href3="/CBO"
        //   buttoncont3="For Community Based Organizations"
          href2="/auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "CBO");
          }}
        />

      





   


    
    










      
     



    </div>
<FooterComponent/>
    </>
  );
}
