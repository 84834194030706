import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";



import { NavLink } from "react-router-dom";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";

export default function GridBanner({data}) {
//   const apps = [
//     {
//       href: "/aamc-app",
//       image: Icon3,
//       title: "onMarketplace",
//     },

//     {
//       href: "/dtn-app",
//       image: Icon2,
//       title: "BulkonMarketplace",
//     },
   
    
//   ];

  return (
    <>
    <HeaderComponent/>
    <div maxwidth={12} className="businessPartnerContainer agristackContainer">
      <Container maxWidth="sm" className="gridSection ">
        <Grid container={true} alignItems="center"  justifyContent="space-between"
        spacing={1} direction="row">
          {data.map((e,index) => {
            return (
              <Grid
              key={index}
                id="ecoSystemView_link"
                className="hoverEffect mb-3"
                item
                md={6}
                sm={4}
                xs={6}
              >
                <NavLink to={e.href}>
                  <img src={e.image} alt="ecoSystem & Mobile Apps View " />
                  <h4>{e.title}</h4>
                </NavLink>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
<FooterComponent/>
    </>
  );
}
